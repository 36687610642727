<template>
  <div class="home mx-2">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-text>
            <v-row dense justify="center">
              <v-col cols="12" md="12" sm="12">
                <!-- <div class="dog"></div> -->
                <!-- <div class="fly"></div> -->
                <!-- <div class="text-animate-1 my-6">
                  <p class="static" id="static"></p>
                  <ul class="list" id="list"></ul>
                </div> -->
                <div class="gota"></div>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <h1 class="text-center mt-2" style="color: black">
                  Bienvenid@ a <strong>LuxCultury</strong>
                </h1>
              </v-col>
              <v-col cols="12" md="8" sm="10" id="copy">
                <h3 class="text-center mt-4">
                  Comparte este link con tus clientes para que estos puedan
                  registrarse y/o tomar su cita
                </h3>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="mt-4" v-bind="attrs" v-on="on" :value="$store.state.centro.link"
                      color="primary" type="text" label="Link para clientes" readonly outlined rounded dense
                      @click="copy_link"></v-text-field>
                  </template>
                  <span>
                    Puedes compartir este link con tus clientes.
                    <br />
                    <small>Click para copiar</small>
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "home",
  methods: {
    copy_link(data) {
      const url = data.target.value;
      let input = document.createElement("input");
      input.setAttribute("value", url);
      let copy = document.getElementById("copy");
      copy.appendChild(input);
      input.select();
      document.execCommand("copy");
      copy.removeChild(input);
    },
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify([
        "recepcion",
        "informes",
        "bloqueos",
        "caja",
        "callc",
      ]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });

    // script animacion texto 1
    // const list_ul = ["CONFIABLE", "EFICAZ", "PRÁCTICA", "AGRADABLE"];

    // let txt_static = document.getElementById("static");
    // let list = document.getElementById("list");

    // txt_static.innerText = "LUXCULTURY";
    // list.classList.add("animate-text-1-active");

    // list_ul.forEach((item) => {
    //   let li = document.createElement("li");
    //   let span = document.createElement("span");
    //   span.innerText = item;
    //   span.setAttribute("style", "color: black; font-weight: bold");
    //   // span.setAttribute("style", "color: rgb(25, 118, 210) !important");
    //   li.setAttribute("style", "padding-bottom: 15px")
    //   li.appendChild(span);
    //   list.appendChild(li);
    // });
  },
};
</script>
<style scoped>
.dog {
  margin: 0 auto;
  width: 111px;
  height: 160px;
  background-image: url("../../../public/img/dog.png");
  animation: caminar 1s steps(9) infinite;
}

@keyframes caminar {
  0% {
    background-position: 0px;
  }

  100% {
    background-position: -1000px;
  }
}

.fly {
  margin: 0 auto;
  width: 166px;
  height: 160px;
  background-image: url("../../../public/img/fly.png");
  animation: volar 0.8s steps(6) infinite;
}

@keyframes volar {
  0% {
    background-position: 0px;
  }

  100% {
    background-position: -1000px;
  }
}

.text-animate-1 {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  font-size: 45px;
  line-height: 45px;
  height: 48px;
  overflow: hidden;
}

.static {
  /* font-weight: bold; */
  color: rgb(25, 118, 210) !important;
  /* color: black; */
}

.list {
  list-style: none;
  padding-left: 20px;
}

.animate-text-1-active {
  animation: cambio 8s infinite;
}

@keyframes cambio {
  0% {
    margin-top: 0px;
  }

  20% {
    margin-top: 0px;
  }

  25% {
    margin-top: -60px;
  }

  45% {
    margin-top: -60px;
  }

  50% {
    margin-top: -120px;
  }

  70% {
    margin-top: -120px;
  }

  75% {
    margin-top: -180px;
  }

  95% {
    margin-top: -180px;
  }

  100% {
    margin-top: 0px;
  }
}

.gota {
  margin: 0 auto;
  width: 200px;
  height: 200px;
  background-image: url("../../../public/img/Animacion_gota.png");
  background-repeat: no-repeat;
  animation: gota 1s steps(9) infinite;
}

@keyframes gota {
  from {
    background-position: 0px;
  }

  to {
    background-position: -1800px;
  }
}
</style>
